<template lang="pug">
.verification-code-form
    p {{ title }}
    .numbers-inputs(:class="{'error': errorStyle}")
        input(maxlength="1" @input="changeInput($event,0)")
        input(maxlength="1" @input="changeInput($event,1)")
        input(maxlength="1" @input="changeInput($event,2)")
        input(maxlength="1" @input="changeInput($event,3)")
DefaultButton(text="თავიდან გამოგზავნა" :animation="animation" @clicked="$emit('reSent')")
DefaultLoader(v-if="showLoader")
</template>

<script>
import DefaultButton from "../layout/DefaultButton";
import DefaultLoader from "../layout/DefaultLoader";
import {mapGetters} from "vuex";

export default {
    name: "VerificationCodeForm",
    emits: ['validateCode', 'reSent', 'changeShowLoader'],
    props: {
        animation: {
            type: Boolean,
            default: null
        },
        showLoader: {
            type: Boolean,
            default: null
        },
        title: {
            type: String,
            default: null
        }
    },
    components: {
        DefaultButton,
        DefaultLoader
    },
    data() {
        return {
            values: [],
            code: null,
            error: true,
            errorStyle: false
        }
    },
    methods: {
        changeInput(e, index) {
            const el = e.target
            this.values[index] = el.value

            if (el.value.length && el.nextElementSibling)
                e.target.nextElementSibling.focus()
            else if (!el.value.length && el.previousElementSibling)
                e.target.previousElementSibling.focus()
        },
        showError() {
            setTimeout(() => {
                this.$emit('changeShowLoader', false)
                if (this.error) {
                    this.errorStyle = false
                    setTimeout(() => {
                        this.errorStyle = true
                    }, 0)
                }
            }, 3000)
        }
    },
    watch: {
        values: {
            handler: function (val) {
                this.code = val.join("")
                if (this.code.length === 4) {
                    this.$emit('changeShowLoader', true)
                    this.$emit('validateCode', this.code)
                    this.showError()
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.verification-code-form {
    width: 258px;
    padding: 0 20px;
    color: #434242;

    & > p {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        margin: 33px 0 10px;
    }

    .numbers-inputs {
        display: grid;
        grid-template-columns: repeat(4, 32px);
        justify-content: center;
        grid-column-gap: 15px;
        margin-bottom: 20px;

        input {
            width: 32px;
            height: 30px;
            box-shadow: 0 3px 20px #0000000D;
            border: 1px solid #6E6E6E;
            border-radius: 5px;
            text-align: center;


            &:focus {
                outline: none;
            }
        }

        &.error {
            input {
                border-color: #F21D1D;
                animation-name: error;
                animation-duration: .3s;
                animation-fill-mode: forwards;
            }
        }
    }

    @keyframes error {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(10px);
        }
        50% {
            transform: translateX(0);
        }
        70% {
            transform: translateX(10px);
        }
        100% {
            transform: translateX(0);
        }
    }

}
</style>
