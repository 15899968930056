<template lang="pug">
form-layout(title="ᲕᲔᲠᲘᲤᲘᲙᲐᲪᲘᲐ")
	template(#right)
		firstStep(v-if="!$route.query.hash")
		VerificationCodeForm(
			v-else
			@validateCode="validateCode"
			@reSent="reSent"
			@changeShowLoader="value => this.showLoader = value"
			:title="codeFormTitle"
			:animation="animation"
			:showLoader="showLoader"
		)
</template>

<script>
import {mapGetters} from "vuex";

import FormLayout from "../components/layout/FormLayout";
import firstStep from "../components/forms/VerificationFirstStep";
import VerificationCodeForm from "../components/forms/VerificationCodeForm";

export default {
	name: "Verification",
	components: {
		VerificationCodeForm,
		FormLayout,
		firstStep,
	},
	data() {
		return {
			animation: true,
			showLoader: false
		}
	},
	mounted() {
		this.$store.dispatch("user/userLastNumbers", {
			uid: this.$route.params.uid,
			token: this.$route.params.token
		})
	},
	computed: {
		...mapGetters({
			lastNumbers: 'user/phoneLastNumbers'
		}),
		codeFormTitle() {
			return `შეიყვანეთ 5*****${this.lastNumbers} ნომერზე გამოგზავნილი კოდი`
		}
	},
	methods: {
		reSent() {
			this.$store.dispatch("user/userActiveCodeSend", {
				uid: this.$route.params.uid,
				token: this.$route.params.token
			}).then(res => {
				this.$router.push({query: {hash: res.hash}})
				this.animation = false
				setTimeout(() => {
					this.animation = true
				}, 0)
			})
		},
		validateCode(code) {
			this.$store.dispatch("user/userActiveCodeValidate", {
				uid: this.$route.params.uid,
				token: this.$route.params.token,
				params: {
					hash_key: this.$route.query.hash,
					code: code
				}
			}).then(() => {
				this.showLoader = false
				this.$router.push('/login')
			}).catch(() => {
				this.showLoader = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
