<template lang="pug">
.loader
    img(src="@/assets/images/loader.svg")
</template>

<script>
export default {
    name: "DefaultLoader"
}
</script>

<style lang="scss" scoped>
.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.73);

    img {
        animation-name: example;
        animation-duration: 5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}

@keyframes example {
    from { transform: rotate(0); }
    to { transform: rotate(365deg); }
}
</style>
