<template lang="pug">
.first-step
    p ვერიფიკაციის კოდი გამოიგზავნება ნომერზე: 5*****{{lastNumbers}} თუ ნომერი თქვენ არ გეკუთვნით, დაგვიკავშირდით Support-ზე
DefaultButton(text="ᲙᲝᲓᲘᲡ ᲛᲘᲦᲔᲑᲐ" @clicked="sendCode()")
</template>

<script>
import DefaultButton from "../layout/DefaultButton";
import {mapGetters} from "vuex";

export default {
    name: "VerificationFirstStep",
    emits: ["codeSent"],
    components: {
        DefaultButton
    },
    computed: {
        ...mapGetters({
            lastNumbers: 'user/phoneLastNumbers'
        })
    },
    methods: {
        sendCode() {
            this.$store.dispatch("user/userActiveCodeSend", {
                uid: this.$route.params.uid,
                token: this.$route.params.token
            }).then( res => {
                this.$router.push({query: {hash: res.hash}})
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.first-step {
    width: 258px;
    padding: 0 20px;
    color: #434242;

    .error {
        img {
            display: block;
            width: 30px;
            margin:  20px auto;
        }
        p {
            color: #F01A1A;
        }
    }

    p {
        font-size: 12px;
        text-align: center;
        line-height: 18px;
    }

    & > p {
        margin: 33px 0 20px;
    }
}
</style>
