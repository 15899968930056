<template lang="pug">
.btn(:class="{'animation': animation}" @click="emitClick")
    .text {{text}}
    .bg
p(v-if="isAnimationBtn")
    span(v-if="animation && timeCounter" ) {{formatTime}}
</template>

<script>
export default {
    name: "DefaultButton",
    emits: ['clicked'],
    props: {
        text: {
            type: String,
            default: null
        },
        animation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            animationTime: 60,
            timeCounter: null,
            formatTime: null,
            isAnimationBtn: false
        }
    },
    mounted() {
        this.isAnimationBtn = this.animation
        this.timeCounter = this.animationTime
        this.timerInterval()
    },
    methods: {
        timerInterval() {
            let minutes = Math.floor(this.timeCounter / 60);
            let seconds = this.timeCounter - minutes * 60;
            seconds = seconds < 10 ? "0" + seconds : seconds
            this.formatTime = minutes + ":" + seconds

            let timeDownID = setInterval(() => {
                this.timeCounter--
                minutes = Math.floor(this.timeCounter / 60);
                seconds = this.timeCounter - minutes * 60;
                seconds = seconds < 10 ? "0" + seconds : seconds

                this.formatTime = minutes + ":" + seconds
                if (this.timeCounter === 0)
                    clearInterval(timeDownID)
            }, 1000)
        },
        emitClick() {
            if(!this.animation || !this.timeCounter)
                this.$emit('clicked')
        }
    },
    watch: {
        animation(val) {
            if(val) {
                this.timeCounter = this.animationTime
                this.timerInterval()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn {
    user-select: none;
    position: relative;
    cursor: pointer;
    width: 264px;
    height: 40px;
    line-height: 40px;
    background-color: #9A9A9A;
    box-shadow: 0 3px 20px #0000000D;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    overflow: hidden;

    .text {
        position: relative;
        z-index: 1;
    }

    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #83E89E;
        border-radius: 10px;
    }

    &.animation {
        .bg {
            animation-name: example;
            animation-duration: 60s;
            animation-timing-function: linear;
        }
    }
}

p {
    margin-top: 8px;
    height: 20px;

    span {
        color: #434242;
        font-size: 14px;
    }
}

@keyframes example {
    from { width: 0; }
    to { width: 100%; }
}
</style>
