<template lang="pug">
.form-layout
    slot(name="left")
        img(src='@/assets/images/loginImage.png')
    .right-wrapper
        img(src='@/assets/images/close.png' @click="closePage")
        h1 GRAEY
        .content
            h1 {{title}}
            slot(name="right")
        .footer
            a(href="#") FAQ
            a(href="#") Terms & Conditions
            a(href="#") Safety

</template>

<script>
export default {
    name: " FormLayout",
    props: ["title"],
    methods: {
        closePage() {
            this.$router.push({name: "Home"})
        }
    }
}
</script>

<style lang="scss" scoped>
.form-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100vh;


    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 992px) {
            display: none;
        }
    }

    .right-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        & > img {
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 18px;
            width: 15px;
        }

        & > h1 {
            margin: 30px 0 0 40px;
            font-size: 26px;
            color: #212121;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px 0;

            & > h1 {
                color: #434242;
                font-size: 18px;
                text-align: center;
            }
        }

        .footer {
            padding-bottom: 20px;
            display: flex;
            justify-content: center;

            a {
                padding: 0 10px;
                color: #6E6E6E;
            }
        }
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}
</style>
